<template>
  <div :class="(expanded ? 'expanded' : '') + ' order'">
    <div class="thumbnail">
      <img :src="order.LINK_IMAGEM" :alt="order.PRO_NOME" />
    </div>
    <div class="detalhes">
      <div class="dados-compra">
        <div class="titulo">
          <b>{{ $t('etapas.produto.tituloCompra') }}:</b>
        </div>

        <div class="dados">
          <div class="apenas-expandido">
            <b>{{ order.PRO_NOME }}</b>
          </div>
          <div class="apenas-expandido cor-tamanho">
            <div class="flex">
              <div class="dass-product-size-color">
                <span>{{ $t('etapas.produto.cor') }}: </span>
                <b>{{ order.COR_NOME }}</b>
              </div>
              <div class="dass-product-size-color">
                <span>{{ $t('etapas.produto.tamanho') }}: </span>
                <b>{{ order.GRA_TAMANHO }}</b>
              </div>
            </div>
          </div>
          <div class="numero-pedido">
            <span>{{ $t('dass.numeroPedido') }}:</span>
            <b>{{ order.PED_CODIGO }}</b>
          </div>
          <div class="data-valor-compra">
            <div>
              <span>{{ $t('etapas.produto.dataCompra') }}:</span>
              <b>{{ $filters.toDateBRL(order.DATA, 'D/M/YY') }}</b>
            </div>
            <div>
              <span>{{ $t('etapas.produto.valueProduct') }}:</span>
              <b>{{ $filters.toMoney(order.ITE_VALBRUTO || 0) }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="trocar-devolver">
      <form class="trocar-devolver" action="" @submit.prevent="formSubmit">
        <div>
          <b>{{ $t('home.trocaDevolucao') }}:</b>
        </div>
        <div class="campos">
          <div class="campo selects">
            <dass-select
              name="select-motivo"
              :label="$t('etapas.produto.motivo') + ':'"
              :required="true"
              :options="motivos"
              @change="
                (val) => {
                  if (typeof val == 'object') return
                  form_data.motivo = val
                  handleReasons(val)
                }
              "
            >
            </dass-select>
            <dass-select
              name="select-motivo"              
              id="dass-sub-motivo-select"
              :label="$t('etapas.produto.subMotivo') + ':'"
              :disabled="this.subReasons.length == 0"
              :required="true"
              :options="subMotivo"
              @change="
                (val) => {
                  if (typeof val == 'object') return
                  form_data.smotivoCodigo = val               
                }
              "              
            >
            </dass-select>
            <dass-select
              name="select-desejo"
              id="dass-select-desejo"
              :label="$t('etapas.produto.desejo') + ':'"
              :required="true"
              :options="desejos"
              @change="
                (val) => {
                  if (typeof val == 'object') return                  
                  form_data.desejo = val                                   
                }
              "
            >
            </dass-select>
          </div>
          <div class="campo conjunto-tamanho">
            <div>
              <dass-textarea
                type="text"
                :required="true"
                :label="$t('etapas.produto.observacoes') + ':'"
                @change="
                  (val) => {
                    if (typeof val == 'object') return
                    form_data.detalhes = val                    
                  }
                "
              />
            </div>
            <div v-show="input_troca" :class="!this.isSafari ? 'input-troca' : ''">
              <dass-input-text
                type="text"
                :label="$t('etapas.produto.selecioneTamanho') + ':'"
                :required="input_troca"
                v-model="form_data.tamanho"
                v-mask="'XXX'"
              />
            </div>
          </div>
        </div>
        <div class="sub-acoes">
          <div>
            <dass-link
              :id="`dass-link-order-${this.brandName}`"            
              @click="
                () => {
                  expanded = !expanded
                }
              "
            >
              {{ $t('home.minimizar') }}
            </dass-link>

            <dass-button :class="this.isSafari ? `dass-button-primary-${this.brandName}` : ''" variant="primary" type="submit">
              {{ label_btn }}
            </dass-button>
          </div>
        </div>
      </form>
    </div>

    <div class="acoes">
      <dass-button
        class="button-acesso-rapido"
        variant="secondary"
        type="button"
        @click="
          () => {
            expanded = !expanded
          }
        "
      >
        {{ $t('dass.visualizarItem') }}
      </dass-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { MOTIVOS, DESEJOS } from '../../../helpers/constants/motivos_desejos'
import { ctoId } from '../../../helpers/constants/ctoId'
export default {
  name: 'Order',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('create_protocol', {
      subjects: 'getSubjects',
      reasons: 'getReasons',
      allSubReasons: 'getAllSubReasons',
      subReasons: 'getSubReasons'
    }),
    ...mapGetters('brand', { brand: 'getBrand' }),
    input_troca: function () {
      if (
        this.form_data.motivo == MOTIVOS.TROCA_TAMANHO &&
        this.form_data.desejo == DESEJOS.MESMO_PRODUTO
      )
        return true

      return false
    },

    motivos: function () {
      let motivos = []
      this.subjects.forEach((reason) => {
        if (!reason.ASS_NOME) return
        let temp = {}
        temp.text = reason.ASS_NOME
        temp.value = reason.ASS_ID

        motivos.push(temp)
        return temp
      })
      motivos.unshift({
        text: this.$t('etapas.produto.selecioneMotivo'),
        value: '',
        disabled: true,
        selected: true,
      })
      return motivos
    },
    subMotivo: function () {
      
      let subMotivos = []      
      this.subReasons.forEach((subReason) => {
        if (!subReason.SMOT_NOME) return
        let temp = {}
        temp.text = subReason.SMOT_NOME
        temp.value = subReason.SMOT_ID

        subMotivos.push(temp)
        return temp
      })
      subMotivos.unshift({
        text: this.$t('etapas.produto.selecioneSubMotivo'),
        value: '',
        disabled: true,
        selected: true,
      })
      
      return subMotivos
    },
    desejos: function () {
      let desejos_tratado = []
      let ass_id = this.form_data.motivo || 16
      if (ass_id == 15) ass_id = 16
      desejos_tratado = this.reasons.filter((motivo) => {
        return motivo.ASS_ID == ass_id
      })
      
      let desejos = []
      desejos_tratado.forEach((desejo) => {
        if (!desejo.MOT_NOME) return
        if (desejo.MOT_ATIVO != 'S') return
        let temp = {}
        temp.text = desejo.MOT_NOME
        temp.value = desejo.MOT_ID
        desejos.push(temp)
        return temp
      })
      desejos.unshift({
        text: this.$t('etapas.produto.selecioneDesejo'),
        value: '',
        disabled: true,
        selected: true,
      })
      return desejos
    },
    motivo: function () {
      let motivo = this.subjects.filter((motivo) => {
        return motivo.ASS_ID == this.form_data.motivo
      })
      if (!motivo.length) return { ASS_ID: -1, MOT_NOME: '' }
      return motivo[0]
    },
    desejo: function () {
      const desejo = this.reasons.filter((desejo) => {
        return desejo.MOT_ID == this.form_data.desejo
      })
      if (!desejo.length) return { MOT_ID: -1, ASS_NOME: '' }
      return desejo[0]
    },
    action_fotos: function () {
      const motivo = this.motivo
      if (
        motivo.ASS_ID == this.MOTIVOS.A_PECA_QUE_RECEBI_NAO_FOI_A_QUE_COMPREI ||
        motivo.ASS_ID == this.MOTIVOS.O_PRODUTO_CHEGOU_COM_DEFEITO
      )
        return true
      return false
    },
    label_btn: function () {
      if (this.action_fotos) return this.$t('home.confirmar')
      return this.$t('home.adicionar')
    },
  },  
  methods: {
    ...mapActions('create_protocol', ['createItem', 'loadSubReasons']),
    ...mapActions('orders', ['selectOrder', 'toggleSidebar', 'clearSelectedOrders']),    
    
    next: function () {
      this.$router.push({
        path: '/produtos-adquiridos-online/comprador',
      })
    },
    formSubmit: function () {      
      const order = this.order  
      const isValidSubReason = this.validateSubReasons() 
      if(!isValidSubReason) return    
      let item = {
        itemDevolucao: order.QUANTIDADENF,
        agrupador: '',
        pedTipoPgto: order.PED_TIPOPGTO,
        produtoCodigo: order.PRO_CODIGO,
        produtoNome: order.PRO_NOME,
        produtoCor: order.COR_NOME || '',
        produtoValor: parseFloat(order.ITE_VALBRUTO || 0),
        motivoCodigo: parseInt(this.motivo.ASS_ID || 0),
        motivoNome: this.motivo.ASS_NOME || '',
        smotivoCodigo: this.form_data.smotivoCodigo || '',
        desejoCodigo: this.desejo.MOT_ID,
        desejoNome: this.desejo.MOT_NOME,
        gradeCodigo: order.GRA_CODIGO,
        gradeTamanho: order.GRA_TAMANHO,
        tamanhoTroca: this.form_data.tamanho || '',
        empresaCodigo: order.PED_EMPRESA || '',
        filialCodigo: order.PED_FILIAL,
        sequenciaFat: order.FAT_SEQUENCIA,
        itemFatSequencia: order.ITE_SEQUENCIA,
        numeroNF: order.NUMERONF,
        quantidadeNF: order.QUANTIDADENF,
        qtdDevolucao: order.QUANTIDADENF,
        observacao: this.form_data.detalhes || '',
        ecommerce: true,
        tipo_contato: order.ORIGEM == 'eComm' ? ctoId.eCommerce : order.ORIGEM == 'Omni' ? ctoId.Omni : ctoId.Reclamacao,
        thumbnail: order.LINK_IMAGEM || '',
        dataCompra: order.DATA || '',
        compraValor: order.PED_VALTOTAL || '',
        motivo: this.motivo || '',
        desejo: this.desejo || '',
        UNIQUE_KEY: order.UNIQUE_KEY || '',
      }     
      
      this.selectOrder(item)
      if (this.action_fotos) {        
        this.clearSelectedOrders() 
        this.createItem(item)
        return this.next()
      }
      this.toggleSidebar(true)
    },    
    clearSubMotivoAndDesejo() {
      this.form_data.smotivoCodigo = ''
      this.form_data.desejo = ''
      const eleSubReason = document.getElementById('dass-sub-motivo-select')
      const eleDesejo = document.getElementById('dass-select-desejo')
      eleDesejo.value = ''
      eleSubReason.value = ''  
    },
    async handleReasons(val) { 
      if(val && typeof val !== 'object') {  
        this.clearSubMotivoAndDesejo() 

        try {
          await this.loadSubReasons({
            assId: val
          })            
        } catch (error) {
          this.errorCount++          
          this.$ShowAlert({
            variant: 'error',
            message: error.message,  
            hideDelay: 3000                 
          }) 
          if(this.errorCount > 3) {
            this.errorCount = 0
            setTimeout(() => {
              location.reload()    
            }, 3000)
          } else {
            setTimeout(() => {
              this.handleReasons(val) 
            }, 2000)
          }         
        }
      }
    },  
    validateSubReasons() {     
      const requiredSubReasons = this.allSubReasons.some(item => {
        return item.ASS_ID == this.motivo.ASS_ID
      })      
      if(requiredSubReasons && !this.form_data.smotivoCodigo) {
        this.$ShowAlert({
          variant: 'warning',
          message: this.$t('etapas.produto.subMotivo')
        })
        return false
      }
      if(this.allSubReasons.length == 0) {        
        location.reload()
      }
      return true
    },    
  },
  data() {
    return {
      isSafari: false,
      brandName: '',
      reasonsSelected: '',
      expanded: false,  
      errorCount: 0,
      errorCountAllSubReasonsRequest: 0,    
      form_data: {
        motivo: '',
        desejo: '',
        detalhes: '',
        tamanho: '',
        smotivoCodigo: ''
      },
      MOTIVOS: {
        A_PECA_QUE_RECEBI_NAO_FOI_A_QUE_COMPREI: 19,
        O_PRODUTO_CHEGOU_COM_DEFEITO: 20,
      },
    }
  },
  created() {    
    this.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)
    this.brandName = this.brand.name.replace(/\s/g, '').toLowerCase()  
  }
}
</script>

<style lang="scss">
.order {
  border-bottom: 2px solid var(--theme-gray_light);
  &:last-of-type {
    border: none;
  }

  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  padding: var(--space) 0;

  @media (max-width: 1024px) {
    align-items: flex-start;
    padding: var(--space__0-25x) 0;
    flex-wrap: wrap;
    padding-bottom: var(--space);
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  .apenas-expandido {
    display: none;
  }
  .thumbnail {
    transition: all 200ms;
    width: 100px;
    @media (max-width: 1024px) {
      width: 40%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .detalhes {
    flex: 1;
    padding: 0 var(--space);
    display: flex;
    @media (max-width: 1024px) {
      padding: 0;
      padding-left: var(--space);
      width: 60%;
    }
    span,
    b {
      line-height: 0.8rem;
      word-break: break-all;
    }
    .dados-compra {
      width: 100%;
      .titulo {
        padding-bottom: var(--space__0-5x);
      }
      .dados {
        display: flex;
        @media (max-width: 1024px) {
          flex-wrap: wrap;
          line-height: 0.3rem;
          .flex > div {
            flex-wrap: wrap;
            display: flex;
            margin-bottom: var(--space__0-25x);
          }
        }
        .dass-product-size-color {
          @media (max-width: 700px) {
            row-gap: 0.3rem;            
          }
        }

        > div {
          padding-right: var(--space);
          margin-bottom: var(--space__0-5x);
          &:last-of-type {
            margin-bottom: 0;
          }
          @media (max-width: 1024px) {
            flex-wrap: wrap;            
            margin-bottom: var(--space__0-25x);
            span {
              width: 100%;
              display: block;
            }
          }
          span {
            margin-right: var(--space__0-5x);
            color: var(--theme-gray);
          }
        }
        .data-valor-compra {
          display: flex;
          flex-wrap: wrap;
          > div {
            margin-right: var(--space);
            @media (max-width: 1024px) {
              margin: 0;
              
              > span {
                margin-bottom: var(--space__0-25x);
              }
            }
          }
        }
        .flex {
          display: flex;
          flex-wrap: wrap;
          > div:first-of-type {
            margin-right: var(--space);
          }
        }
      }
    }
  }
  .trocar-devolver {
    height: 0;
    width: 0;
    overflow: hidden;
    transition: all 400ms;
    display: flex;    
    @media (max-width: 1024px) {
      width: initial;    
      flex-wrap: wrap;
      .campos {
        width: 100%;
        .campo > div {
          margin-bottom: var(--space);
        }
      }
      .sub-acoes {        
        width: 100%;
        margin-top: var(--space__0-5x);
        > div {
          width: 100%;
          justify-content: space-between;
          display: flex;
          .dass-link {
            flex: 1;
            justify-content: center;
          }
          button {
            padding: var(--space__0-5x) var(--space);
          }
        }
      }
    }

    .sub-acoes {
      @media (min-width: 1024px) {
        padding: 0 var(--space__0-5x);
      }
      .dass-link {
        font-weight: bold;
        margin-right: var(--space);
      }
      button {
        padding: var(--space__0-5x) var(--space__2x);
      }    
    }    
  }
  .acoes {
    transition: all 400ms;
    @media (max-width: 1024px) {
      width: 100%;
      margin-top: var(--space__0-5x);
      button {
        width: 100%;
      }
    }
    button {
      padding: var(--space__0-5x) var(--space__2x);
    }
  }
  &.expanded {
    align-items: center;

    .apenas-expandido {
      display: block;
    }
    @media (max-width: 1024px) {
      .apenas-expandido {
        &.cor-tamanho {
          display: inline;
          span {
            width: initial !important;
          }
        }
      }
      .thumbnail {
        width: 35%;
      }
      .trocar-devolver {
        width: 100%;
        height: 100%;
      }
      .acoes {
        height: 0;
        overflow: hidden;
      }
    }
    @media (min-width: 1024px) {
      align-items: stretch;
      .thumbnail {
        width: 200px;
      }
      .detalhes {
        .dados-compra {
          width: 100%;
          .dados {
            flex-wrap: wrap;
            > div {
              width: 100%;
            }
            .numero-pedido {
               span {
                display: block;                           
              }
            }

            .data-valor-compra {
              display: flex;
              flex-wrap: wrap;
              > div {                
                margin-right: 0;
                span {
                  display: block;
                }
              }
            }
          }
        }
      }
      .trocar-devolver {
        height: initial;
        flex: 2;
        display: flex;
        flex-wrap: wrap;
        padding: 0 0.5rem;

        > div {
          width: 100%;
        }
        .campos {
          display: flex;
          padding-bottom: 0.5rem;
          .campo {
            width: 50%;
            &:first-of-type {
              padding-right: 0.5rem;
            }
            &:last-of-type {
              padding-left: 0.5rem;
            }
            &.selects {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
          }
          .conjunto-tamanho {
            display: flex;
              flex-direction: column;
              justify-content: space-between;             
              
            > div {
              display: flex;
              align-items: stretch;                             
              .dass-textarea,
              .dass-input-text {
                width: 100%;
                display: flex;
                height: 100%;
                .dass-input-text--input-wrapper {
                  width: 100%;
                }
                textarea,
                input {
                  width: 100%;
                  display: flex;
                  height: 100%;                  
                }
              }
            }
            > .input-troca {
              margin-bottom: var(--space__0-5x)
            }
          }
        }
        .sub-acoes {
          display: flex;
          justify-content: flex-end;
        }
      }
      .acoes {
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
  @media(max-width: 1024px) {
    .numero-pedido {
      > span {
       margin-bottom: var(--space__0-25x);                           
     }
    }
  }
  #dass-link-order-umbro {
    color: var(--theme-color) !important;
    padding: var(--space__0-5x);   
    transition: background-color 0.3s ease !important;
    &:hover {
      background-color: #737373 !important;
    }
  }
}
</style>

<template>
  <customer-view-template>
    <div class="home">
      <order-list></order-list>
      <div class="produtos-fora-loja-online">
        <div class="container">
          <div>
            <b> {{ $t('home.produtosFisicos') }} </b>
          </div>
          <div>
            <dass-button
              class="button-acesso-rapido"
              variant="secondary"
              type="button"
              @click="
                () => {
                  $router.push({
                    path: '/produtos-adquiridos-fora-da-loja-online/produto',
                  })
                }
              "
            >
              {{ $t('dass.cliqueAqui') }}
            </dass-button>
          </div>          
        </div>
      </div>
    </div>
    <sidebar />
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import OrderList from '../components/organisms/home/OrderList'
import Sidebar from '../components/organisms/home/sidebar/Sidebar'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Home',
  components: { CustomerViewTemplate, OrderList, Sidebar },
  computed: {
    ...mapGetters('orders', {
      orders: 'getOrders'      
    }),    
  },
  methods: {
    ...mapActions('orders', ['selectOrder', 'toggleSidebar']),
  },
}
</script>

<style lang="scss">
.home {
  text-align: left;
  padding-bottom: 60px;
  .produtos-fora-loja-online {
    background: var(--theme-white);
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;    
    z-index: 11; 
    width: 100%;
    display: flex;
    justify-content: center;
    border: var(--theme-border);
    border-color: var(--theme-gray_light);
    .container {
      width: 80%;
      display: flex;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        &:first-of-type {
          padding-right: var(--space);
        }
        padding: var(--space__0-5x);
        button {
          padding: initial;

          width: 100%;
          height: 100%;
          padding: var(--space__0-5x) var(--space__2x);
        }
      }
      @media (max-width: 1024px) {
        padding: var(--space);

        width: 100%;
        > div {
          width: 50%;
          line-height: 1rem;
          b {
            color: var(--theme-gray);
          }
          button {
            padding: var(--space__0-75x) var(--space);
          }
        }
      }
      @media (max-width: 700px) {
        padding: 0.5rem;
      }
    }
  }
  
}

.theme-umbro {
  .produtos-fora-loja-online {
    border: none !important;
    background-color: var(--theme-color) !important;
    b {
      color: var(--theme-white);
    }
  }
}
</style>
<template>
  <div class="t-customer-view">
    <dass-header id="header-top" logoUrl="/home">
      <template v-slot:left-content>
        <router-link :to="`/home`" :title="$t('dass.pgInicial')">
          <div class="logo">
            <img
              :alt="`${brand.name} Logo`"
              :src="requireImage(`theme/${brand.folder}/logo.png`)"
            />
            <div v-if="!is_mobile" class="version-container">
              <small> v: {{ version }} </small>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:right-content>
        <div v-if="countSelected > 0"            
        @click="() => {        
          this.$route.name  == 'Home' ? 
          this.toggleSidebar(true) :
          this.$router.push('/home')     
        }" 
        class="dass-badge-icon-returns"
        >
          <dass-badge type="number" variant="secondary">
            <template #default> {{ countSelected }} </template>
          </dass-badge>          
          <span :class="`dass-icon-returns-${brandName}`"></span>
        </div>
        <dass-nav>
          <dass-nav-item to="/protocolos" id="link-to-protocols">
            {{ $t('protocolos.protocolos') }}
          </dass-nav-item>
          <dass-nav-item to="/home" id="link-to-home">
            {{ $t('dass.pgInicial') }}
          </dass-nav-item>
          <dass-nav-item to="/login" id="btn-logout">
            {{ $t('dass.sair') }}
          </dass-nav-item>
          <dass-nav-item>
            <language-selector></language-selector>
            <div class="version" v-if="is_mobile">
              <small> v: {{ version }} </small>
            </div>
          </dass-nav-item>
        </dass-nav>
      </template>
    </dass-header>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import LanguageSelector from '../organisms/customer_view_template/LanguageSelector'
import packageJson from '../../../package'
import requireImage from '../../helpers/functions/requireImage'


export default {
  name: 'customer-view-template',
  components: { LanguageSelector },
  emits: ['openSideBar'], 
  
  data: () => ({
    version: '',
    is_mobile: false, 
    brandName: ''  
  }),  
  computed: {
    ...mapGetters('brand', { brand: 'getBrand' }),
    ...mapGetters('orders', {      
      selected_orders: 'getSelectedOrders',      
    }),    
    countSelected() {
      let count = 0
      count = Object.keys(this.selected_orders).length || 0
      return count
    },
  },  
  methods: {
    ...mapActions('orders', ['toggleSidebar']),
    requireImage,
  },
  created: function () {      
    this.version = packageJson.version || 0
    this.brandName = this.brand.name.replace(/\s/g, '').toLowerCase()
    let current_width = document.body.clientWidth
    if (current_width < 576) this.is_mobile = true
  },
}
</script>

<style lang="scss">
.dass-header {
  background-color: var(--theme-white) !important;
  border-bottom: 2px solid var(--theme-gray_light);
  .logo {
    position: relative;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .dass-nav {
    background-color: var(--theme-white);
    .dass-link {
      color: var(--theme-gray) !important;
      font-weight: bold;
      transition: color 200ms;
      &:hover,
      &:active,
      &:focus {
        color: var(--theme-black) !important;
        outline: none;
        background-color: var(--theme-white);
      }
    }
  }
  details.dass-language-selector {
    &[open],
    &:focus,
    &:active,
    &:focus-within {
      outline: none !important;
      > summary {
        background-color: var(--theme-white) !important;
      }
    }
  }
  .dass-badge-icon-returns {
    cursor: pointer;
    position: relative;
    padding-top: 12px;
    &:hover { 
      scale: 1.1;
    }
    .dass-badge {
      margin-right: var(--space__0-5x);
      position: absolute;
      top: 0;
      left: -5px;    
               
    }
    .dass-badge--element {
      position: initial !important;
    }
    .texto {
      vertical-align: middle;
    }
    .dass-icon-returns-fila {
      display: inline-block; 
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../assets/files/icons8-devolver-compra-fila.png");
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.5rem;
      @media(max-width: 700px) {
        width: 2rem;
        height: 2rem;
      }
    }
    .dass-icon-returns-umbro {
      display: inline-block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../assets/files/icons8-devolver-compra-umbro.png");
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.5rem;
      @media(max-width: 700px) {
        width: 2rem;
        height: 2rem;       
      }
    }
    .dass-icon-returns-newbalance {
      display: inline-block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../assets/files/icons8-devolver-compra-newbalance.png");
      width: 2rem;
      height: 2rem;  
      margin-right: 0.5rem;   
    }
  }
}
@media (min-width: 576px) {
  .dass-header {
    padding: 0 10vw;
  }
  .logo {
    max-width: 200px;
  }
  .version-container {
    color: var(--theme-gray);
    font-weight: bold;
    position: absolute;
    bottom: -10px;
    right: -35px;
  }
  .dass-nav .version {
    display: none;
  }
}
@media (max-width: 700px) {
  .logo {
    display: flex;
    width: 60%;
  }
  .dass-nav {
    background: var(--theme-white) !important;
    .dass-nav--toggle-button {
      background: var(--theme-white) !important;
      color: var(--theme-black) !important;
      &:focus,
      &:active,
      &:focus-within {
        outline: none !important;
      }
    }
    .dass-nav--menu {
      background: var(--theme-white) !important;
    }
  }
  .dass-nav .version {
    padding: var(--space);
    position: absolute;
    right: 0;
    bottom: 0;
    color: var(--theme-gray);
  }
  .version-container {
    display: none;
  }
}

.page-content {
  padding: var(--space);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    width: 80%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
.t-customer-view {
  .dass-nav--menu--item {
    display: flex !important;
    align-items: center;
  }
}

.theme-umbro .t-customer-view {
  .dass-header {
    padding: 0.5rem 10vw;
    background-color: var(--theme-color) !important;
    .logo {
      max-width: 70px !important;
    }
    .dass-nav {
      background-color: var(--theme-color) !important;
      li a {
        color: var(--theme-white) !important;
        &:hover {
          color: var(--theme-white) !important;
          text-decoration: underline !important;
          background-color: var(--theme-color) !important;
        }
      }
    }
  }
}
</style>

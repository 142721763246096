import store from '../../store'

import tokenValidate from './tokenValidate'
import { showError } from '../../store/modules/toasts/actions'
import Cookies from 'js-cookie'

const isLogged = async (to, from, next) => {
  if (to.meta.freeAccess) return next()

  let hasUser = false
  let session = store.getters['sesion/getSession'] || {}
  if (session.hasOwnProperty('user')) {
    if (session.user.hasOwnProperty('email')) {
      if (session.user.email !== '') hasUser = true
    }
  }

  if (!hasUser) {
    let cookie = Cookies.get('usu_email')
    if (cookie && cookie != '') hasUser = true
    await store.dispatch('session/loadSessionFromCookie')
  }

  if (hasUser) return next()

  showError(
    'Houve um erro ao carregar sua sessão faça login novamente.',
    'warning'
  )
  return next()
  // return next('/logout')
}

export default isLogged
